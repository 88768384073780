import { apiInstance } from '@/core/axios';
export default {
  state: {
    balance: 0,
    balanceLink: '',
    cryptoLink: '',
    userEmail: ''
  },
  getters: {
    getBalanceLink(state) {
      return state.balanceLink
    },
    getCryptoLink(state) {
      return state.cryptoLink
    },
    getMyBalance (state) {
      return state.balance
    }
  },
  mutations: {
    setBalanceLink (state, link) {
      state.balanceLink = link
    },
    setCryptoLink (state, link) {
      state.cryptoLink = link
    },
    setEmail (state, email) {
      state.userEmail = email
    },
    setMyBalance (state, payload) {
      state.balance = payload
    }
  },
  actions: {
    async setCryptoLink({ rootState, dispatch, commit }, formData) {
      try {
        const response = await apiInstance.post('/api/payments/initiate-crypto/', formData, {
          headers: {
            'Authorization': `Bearer ${rootState.auth.token}`,
            'Content-Type': 'application/json'
          }
        })

        commit('setCryptoLink', response.data.url)

        return response.data.url
      } catch (error) {
        console.log(error)
      }
    },
    async setBalance({ rootState, dispatch, commit }, formData) {
      try {
        const response = await apiInstance.post('/api/payments/initiate/', formData, {
          headers: {
            'Authorization': `Bearer ${rootState.auth.token}`,
            'Content-Type': 'application/json'
          }
        })

        commit('setBalanceLink', response.data.checkout)

        return response.data
      } catch (error) {
        console.log(error)
      }
    },
    async getCash() {
      try {
        await apiInstance.get('https://finaltestundress-de54dd54b61b.herokuapp.com/api/payments/initiate')
      } catch (error) {
        console.log(error)
      }
    }
  }
}
