<template>
  <ModalWrapper :model-name="modelName">
    <div class="redirecting-modal">
      <div class="add-cash-modal-head">
        <p class="add-cash-modal-head_title">
          {{ $t('modals.attention') }}
        </p>
        <div @click="onClose" class="add-cash-modal-head_close">
          <img src="@/assets/icons/close.svg" alt="close remove">
        </div>
      </div>
      <div class="redirecting-modal_body">
        <PaymentButton
          v-for="payment of payments"
          :payment="payment"
          :key="payment.id"
        />
      </div>
      <div class="add-cash-modal_footer">
        <button @click="handleCash" class="add-cash-modal_footer-btn">
          {{ $t('buttons.makeNewPayment') }}
        </button>
      </div>
    </div>
  </ModalWrapper>
</template>
<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import ModalWrapper from "@/components/modals/ModalWrapper.vue";
import PaymentButton from "@/components/PaymentButton.vue";

import cardPayment from "@/assets/visaMaster.svg"
import cryptoPayment from "@/assets/usdtTrc.svg"

export default  {
  name: 'RedirectingModal',
  components: {
    PaymentButton,
    ModalWrapper
  },
  props: {
    modelName: {
      type: String
    },
  },
  setup (props, { emit }) {
    const store = useStore()
    const cardUrl = computed(() => {
      return store.getters.getBalanceLink
    })
    const cryptoUrl = computed(() => {
      return store.getters.getCryptoLink
    })
    const onClose = () => {
      store.commit('removeAllModal')
      emit('closeModal')
    }
    const handleCash = () => {
      store.commit('removeAllModal')
      store.commit('addModal', 'Cash')
    }

    const payments = ref([
      { id: 1, label: 'card', icon: cardPayment, link: cardUrl },
      { id: 2, label: 'usdt', icon: cryptoPayment, link: cryptoUrl }
    ])

    return {
      payments,
      cardUrl,
      cryptoUrl,
      onClose,
      handleCash
    }
  }
}
</script>

<style lang="scss">
.redirecting-modal {
  background-color: rgb(22, 22, 22);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  margin: 0 auto;
  padding: 20px 30px;
  border-radius: 15px;
  width: 500px;

  @media (width <= 550px) {
    width: 380px;
  }
  @media (width <= 420px) {
    width: 95%;
  }

  &_body {
    padding-bottom: 20px;
    font-size: 18px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px;
    @media (width <= 550px) {
      grid-gap: 15px;
    }
  }
}
</style>
